<template>
  <!--begin::Modal - New Target-->
  <div class="modal fade" id="modal_new_icon" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--begin::Heading-->
          <div class="mb-13 text-center">
            <!--begin::Title-->
            <h1 class="mb-3">จัดการ รูปภาพ</h1>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Col-->
          <div class="d-flex flex-column mb-8 fv-row">
            <div style="width: 100%" class="text-center">
              <input
                name="image"
                id="getFile"
                type="file"
                accept="image/jpg, image/jpeg,image/png,"
                @input="pickFile"
                style="display: none"
              />
              <cropper
                v-if="imageUrl"
                class="cropper"
                imageRestriction="none"
                :src="imageUrl"
                :stencil-props="{
                  aspectRatio: aspectRatio,
                }"
                :canvas="{
                  height: height,
                  width: width,
                }"
                @change="onChange"
              />
              <button
                v-else
                style="width: 250px; height: 250px; background-color: #f5f8fa"
                type="button"
                class="form-control btn btn-outline-secondary"
                @click="selectImage"
              >
                <img src="@/assets/picture.png" class="icon-ima" /><br />
                แนบรูป
              </button>
            </div>
            <span v-if="imageStatus" class="text-center mt-1" style="color: red"
              >* กรุณาเลือกรูป</span
            >
          </div>

          <!--begin::Actions-->
          <div class="text-center">
            <button type="button" class="btn btn-white me-3" @click="cancel">
              ยกเลิก
            </button>

            <button type="button" @click="ok" class="btn btn-primary">
              ยืนยัน
            </button>
          </div>
          <!--end::Actions-->

          <!--end::Col-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  name: "new-target-modal",
  components: {
    Cropper,
  },
 props: ["aspectRatio","height","width"],
  setup() {
    let imageStatus = ref(false);
    const imageFile = ref("");
    const imageUrl = ref("");
    const logoUrl = ref("");

    const selectImage = () => {
      document.getElementById("getFile").click();
    };

    function pickFile(e) {
      if (e.target.files.length === 0) {
        imageStatus.value = true;
        return;
      }
      imageFile.value = e.target.files[0];
      imageStatus.value = false;
    }

    watch(imageFile, (imageFile) => {
      let fileReader = new FileReader();

      fileReader.readAsDataURL(imageFile);

      fileReader.addEventListener("load", () => {
        imageUrl.value = fileReader.result;
      });
    });
    function onChange({ canvas }) {
      logoUrl.value = canvas.toDataURL();
    }

    function ok() {
      if (logoUrl.value) {
        let model = document.getElementById("modal_new_icon");
        let body = document.querySelector("body");
        imageStatus.value = false;
        body.classList.remove("modal-open");
        body.style.removeProperty("overflow");
        body.style.removeProperty("padding-right");

        model.classList.remove("show");
        model.style.display = "none";

        const models = document.getElementsByClassName("modal-backdrop");

        while (models.length > 0) models[0].remove();
        this.$emit("clicked", logoUrl.value);
      } else {
        imageStatus.value = true;
      }
    }

    function cancel() {
      imageStatus.value = false;
      imageUrl.value = "";
      logoUrl.value = "";
    }
    return {
      logoUrl,
      imageUrl,
      imageStatus,
      onChange,
      selectImage,
      pickFile,
      ok,
      cancel,
    };
  },
});
</script>
